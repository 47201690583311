import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import bhImg from "../img/crop_bh.png";
export default class CustomCocktails extends React.Component {
  onChange_handler(event) {
    console.log(event.target.value);
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [event.target.id]: value
    });
  }

  reset_handler(event) {
    this.setState({
      spirit: "",
      bitter: ""
    });
  }

  render() {
    // const { data } = this.props;

    return (
      <Layout>
        <div className="container">
          <div className="section">
            <h1 className="title is-1">Custom Cocktails</h1>

            <div className="field">
              <div className="box">
                <div className="content is-large">
                  <p>
                    Let us help you step up your cocktail game for your next
                    event! We offer 3 packages. Let's get in touch!
                  </p>
                </div>
                <div className="columns">
                  <div className="column">
                    <section className="section">
                      {/* <h4 className="has-text-centered has-text-weight-semibold">
                  1
                </h4> */}
                      {/* <h2 className="is-size-1 has-text-weight-bold has-text-warning has-text-centered">
                      1
                    </h2> */}
                      <img src={bhImg} style={{ maxHeight: "80px" }} />
                      <p className="has-text-weight-semibold">
                        Step up your cocktail game
                      </p>
                      <ul>
                        <li className="is-size-5">custom menu card to print</li>
                        <li className="is-size-5">
                          shopping list & detailed instructions
                        </li>
                        <li className="is-size-5">
                          guidance on choosing glassware and serving containers
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div className="column">
                    <section className="section">
                      {/* <h4 className="has-text-centered has-text-weight-semibold">
                  Big
                </h4> */}
                      {/* <h2 className="is-size-1 has-text-weight-bold has-text-warning has-text-centered">
                      2
                    </h2> */}
                      <img src={bhImg} style={{ maxHeight: "80px" }} />
                      <img src={bhImg} style={{ maxHeight: "80px" }} />
                      <p className="has-text-weight-semibold">
                        Great for easy hosting
                      </p>
                      <ul>
                        <li className="is-size-5">Everything in package 1</li>
                        <li className="is-size-5">
                          ingredients delivered, prepped and ready to mix
                        </li>
                      </ul>
                    </section>
                  </div>
                  <form
                    name="custom-cocktail"
                    method="POST"
                    data-netlify="true"
                  >
                    <div className="column">
                      <section className="section">
                        {/* <h4 className="has-text-centered has-text-weight-semibold">
                  Custom
                </h4> */}
                        {/* <h2 className="is-size-1 has-text-weight-bold has-text-warning has-text-centered">
                      3
                    </h2> */}
                        <img src={bhImg} style={{ maxHeight: "80px" }} />
                        <img src={bhImg} style={{ maxHeight: "80px" }} />
                        <img src={bhImg} style={{ maxHeight: "80px" }} />
                        <p className="has-text-weight-semibold">
                          Special drinks for a special occasion
                        </p>
                        <ul>
                          <li className="is-size-5">
                            Everything in package 1 & 2
                          </li>
                          <li className="is-size-5">
                            Mixed ingredients with glassware provided
                          </li>
                          <li className="is-size-5">
                            Just serve and return glassware!
                          </li>
                        </ul>
                      </section>
                    </div>
                  </form>
                </div>
                <hr />
                {/* </div> */}
                <div className="columns">
                  <div className="column is-offset-one-fifth is-three-fifths">
                    <label className="label">Service</label>
                    <div className="field select is-large">
                      <select>
                        {Array.from({
                          length: 3
                        }).map((_, I) => (
                          <option value={I + 1}>{I + 1}</option>
                        ))}
                      </select>
                    </div>

                    <label className="label">Name</label>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <p className=" is-expanded has-icons-left">
                          <input
                            className="input is-large"
                            type="text"
                            placeholder="First Name"
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-user" />
                          </span>
                        </p>
                        <p className=" is-expanded has-icons-left has-icons-right">
                          <input
                            className="input is-large"
                            type="text"
                            placeholder="Last Name"
                            value=""
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-envelope" />
                          </span>
                          <span className="icon is-small is-right">
                            <i className="fas fa-check" />
                          </span>
                        </p>
                      </div>
                    </div>

                    <label className=" label">Email</label>
                    <div className="field has-icons-left has-icons-right">
                      <input
                        className="input is-large"
                        type="email"
                        placeholder=""
                        value=""
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope" />
                      </span>
                      <span className="icon is-small is-right">
                        <i className="fas fa-exclamation-triangle" />
                      </span>
                    </div>

                    <label className="label">City and State</label>
                    <div className="field">
                      <input
                        className="input is-large"
                        type="text"
                        placeholder=""
                      />
                    </div>

                    <label className="label">Number</label>
                    <p>
                      We will call you to confirm and get your address at least
                      24 hours prior to your reservation
                    </p>
                    <div className="field">
                      <input
                        className="input is-large"
                        type="tel"
                        placeholder=""
                      />
                    </div>
                    <label className="label">Special Requests</label>
                    <p>
                      What spirits and flavors do you love or hate? What is the
                      theme or feeling you want to evoke at the party? Do you
                      have a name for your cocktail that will help us create the
                      ingredients?
                    </p>
                    <div className="field">
                      <input
                        className="input is-large"
                        type="text"
                        placeholder=""
                      />
                    </div>

                    <label className="label">Date of Event</label>
                    <div className="field">
                      <input
                        className="input is-large"
                        type="date"
                        placeholder=""
                      />
                    </div>
                    <label className="label">Time</label>
                    <div className="field">
                      <input
                        className="input is-large"
                        type="time"
                        placeholder=""
                      />
                    </div>

                    <label className="label">Party Size</label>
                    <div className="field">
                      <div className="select  is-large">
                        <select>
                          <option value="">Select number of guests</option>
                          {Array.from({
                            length: 30
                          }).map((_, I) => (
                            <option value={I + 1}>{I + 1}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" is-grouped">
                      <div className="field">
                        <button className="button is-fullwidth is-link is-large is-warning">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

// CustomCocktails.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

// export const recipeQuery = graphql`
//   query CustomCocktailsQuery {
//     allCustomCocktailsJson {
//       edges {
//         node {
//           name
//           spirit
//           bitter
//           direction
//           title
//         }
//       }
//     }
//   }
// `;
